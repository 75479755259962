.body {
    @apply px-4 md:px-48 pt-[18vh]
}
.white_card {
    @apply mt-6 min-h-[40vh]
}
.card_nav {
    @apply flex justify-between mb-8
}
.trans_card_title {
    @apply text-3xl md:text-4xl tracking-[-0.36px] font-semibold
}
.transaction_card_holder {
    @apply bg-white h-[65vh] p-4  mt-8 rounded-3xl shadow-sm mb-8 border-solid border-[0.5px] border-grey-20
}
.notifications_row_divider {
    @apply h-[1px] bg-grey-120 w-full
}