.body {
    @apply px-4 md:px-48 pt-[18vh]
}

.greeting {
    @apply text-xl md:text-3xl font-semibold tracking-[-0.36px]
}

.greeting_holder {
    @apply flex flex-col
}

.date {
    @apply text-sm text-grey-130
}

.card_container {
    @apply flex mt-8 mx-0 flex-col p-4 bg-white rounded-3xl border-grey-120 border-solid
}

.card_first_row {
    @apply flex justify-between flex-col md:flex-row
}

.card_card {
    @apply flex items-center md:space-x-4 mt-4 border-b border-grey-120 pb-4 border-solid border-t-0 border-l-0 border-r-0 md:mb-0 md:w-[30%]
}

.card_icon {
    @apply bg-grey-110 p-2 rounded-lg hidden md:inline
}

.mini_card_title {
    @apply text-xs font-medium text-grey-210
}

.mini_card_digit {
    @apply md:text-[1.375rem] text-xl font-bold tracking-[-0.36]
}

.card_col {
    @apply flex flex-col space-y-2
}

.yellow_card {
    @apply w-full flex flex-col justify-center bg-[#FDDD40] md:min-h-[150px] rounded-lg relative my-4
}

.card_wallet_icon {
    @apply absolute bottom-0 md:right-2 right-0
}

.yellow_wallet_title {
    @apply text-sm md:text-base font-semibold text-black md:px-8 px-4 md:pt-6
}

.yellow_wallet_amount {
    @apply text-black font-bold md:px-8 px-4 text-3xl z-10 md:text-6xl
}

.card_action_btn {
    @apply flex flex-col md:flex-row items-center md:space-x-2 px-2 bg-white md:bg-brand-130 rounded-lg border-none text-sm text-brand-50 font-medium md:font-semibold tracking-[-0.24px] cursor-pointer
}

.card_action_btn_icon {
    @apply rounded-full p-2 bg-brand-130
}

.card_action_btn_holder {
    @apply flex space-x-2 justify-between md:justify-start
}

.content_card_nav {
    @apply flex justify-between items-center
}

.view_all_btn {
    @apply border px-4 py-2 rounded-lg text-grey-160 bg-white border-solid cursor-pointer
}

.empty_body {
    @apply bg-white flex flex-col justify-center rounded-lg border border-solid border-grey-120 items-center min-h-[40vh]
}

.content_card_title {
    @apply text-base font-bold mt-4
}

.content_card_text {
    @apply text-xs text-grey-130
}

.content_caption {
    @apply font-semibold tracking-[-0.36px] text-xl
}

.list_parent_holder {
    @apply flex flex-col md:flex-row justify-between
}

.list_parent {
    @apply md:w-[48%]
}

.activate_body {
    @apply flex mt-6 items-center border-solid rounded-lg border-[#FEEDA5] p-4 space-x-4 bg-[#FFFAE6] border
}

.active_row {
    @apply flex justify-between items-center w-full
}

.active_row_title {
    @apply text-base font-bold
}

.row_text_holder {
    @apply flex flex-col w-[60%] space-y-2
}

.active_row_text {
    @apply text-sm font-normal text-grey-130
}

.profile_btn {
    @apply bg-brand-50 p-3 rounded-xl cursor-pointer border-none text-xs font-semibold text-white
}











.text_title {
    @apply font-bold text-xl mb-2
}

.wallet_card {
    @apply bg-black w-[40%] rounded-2xl p-8 relative
}

.balance_card {
    @apply bg-[#9F3FE9] w-[55%] rounded-2xl p-8 relative
}

.card_background {
    @apply absolute bottom-0 right-0 overflow-hidden
}

.wallet_card_holder {
    @apply flex mt-5 space-x-4
}

.wallet_card_title {
    @apply text-white
}

.wallet_card_coin {
    @apply text-xl font-medium
}

.wallet_card_amount {
    @apply text-white font-bold text-[3rem]
}

.wallet_card_btn_holder {
    @apply mt-5 flex space-x-2
}

.wallet_card_btn {
    @apply bg-[#26262A] border-none text-white py-4 px-8 cursor-pointer font-bold text-sm rounded-full
}

.credit_card_btn {
    @apply bg-[#BF84EE] border-none text-white py-4 px-8 cursor-pointer font-bold text-sm rounded-full
}

.balance_content_holder {
    @apply flex justify-between
}

.balance_text_holder {
    @apply flex flex-col
}

.balance_content_title {
    @apply text-white text-xs mt-2
}

.content_text {
    @apply text-white font-bold text-sm mb-4 mt-1
}

.trans_card_title {
    @apply text-xl font-bold
}

.trans_card_btn {
    @apply border-solid border-[0.5px] rounded-full px-4 py-2 text-xs font-bold bg-white text-grey-160
}

.empty_card {
    @apply flex flex-col items-center justify-center
}

.empty_title {
    @apply text-base font-bold mt-4
}

.empty_sub_title {
    @apply text-sm font-medium text-grey-130 mt-1
}

.acoount_funding_container {
    @apply border-solid border-grey-30 p-4 border-[0.5px] rounded-lg bg-grey-150 mb-4
}

.bank_details_holder {
    @apply flex justify-between
}

.bank_title {
    @apply text-grey-130 text-xs font-medium
}

.bank_text {
    @apply text-grey-160 text-xs font-medium
}

.bank_divider {
    @apply h-0.5 bg-grey-120 my-4
}

.fund_Wallet_text {
    @apply text-[#818898] text-xs font-medium
}

.fund_Wallet_text_bold {
    @apply font-bold
}

.loan_max_text {
    @apply text-[#818898] text-xs font-medium
}

.request_summary_card {
    @apply border-solid border-grey-30 p-4 border-[0.5px] rounded-lg mb-4
}

.red_text {
    @apply text-brand-50
}

.selected_options_btn {
    @apply flex items-center space-x-2 p-4 rounded-lg border-solid border-brand-50 w-full bg-brand-130 cursor-pointer;
    border-width: 1.5px;
}

.options_btn {
    @apply flex items-center space-x-2 p-4 rounded-lg border-solid border-grey-120 w-full bg-grey-110 cursor-pointer;
    border-width: 1.5px;
}

.option_btn_text {
    @apply text-black text-sm font-medium
}

.modal_footer_holder {
    @apply flex space-x-1 items-center
}

.request_loan_option_holder {
    @apply flex justify-between w-full
}

.request_loan_option {
    @apply flex flex-col relative cursor-pointer border-solid justify-center items-center w-[48%] rounded-lg py-8 bg-grey-120 border-grey-120
}

.selected_request_loan_option {
    @apply flex flex-col relative cursor-pointer border-solid justify-center items-center w-[48%] rounded-lg py-8 bg-brand-130 border-brand-50
}

.request_loan_option_title {
    @apply text-sm font-medium text-black mt-8
}

.request_radio_btn {
    @apply absolute top-4 left-4
}

.request_title {
    @apply font-medium text-xs text-grey-130
}

.cash_warn {
    @apply text-xs text-black font-medium
}

.cash_warn_holder {
    @apply flex space-x-2 items-center bg-brand-130 px-4 rounded-lg border-solid border border-[#EA6C83] mb-4
}

.request_card_holder {
    @apply flex flex-col space-y-4 border rounded-lg border-solid border-grey-120
}

.transaction_row_dovider {
    @apply h-[1px] bg-grey-120 w-full
}

.transaction_card_holder {
    @apply flex flex-col space-y-4 bg-white p-8 rounded-lg border border-solid border-grey-120
}

.tier_holder {
    @apply flex items-center gap-2 bg-yellow-400 rounded-full px-4
}

.tier_title {
    @apply text-sm font-bold
}

.greeting_tier_holder {
    @apply flex justify-between items-start
}

.mflex_wallet_amount_holder {
    @apply flex items-center
}

.eye_icon {
    @apply cursor-pointer
}