.auth_container {
    @apply flex justify-between relative 
}
.background {
    @apply w-[50%] bg-[#FFFAE6] hidden md:block;
    background-image: url("../../assets/images/AuthBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.auth_title {
    @apply text-grey-190 text-7xl font-bold leading-none;
}
.red_text {
    @apply text-brand-50
}
.text_holder {
    @apply flex flex-col px-12 py-12
}
.auth_sub_title {
    @apply text-grey-130 font-medium text-xl mt-6 opacity-80 mb-[120px]
}
.image_holder {
    @apply absolute bottom-0 right-0 h-[75%] w-[45%]
}
.image {
    @apply h-full w-full
}
.first_chat_holder {
    @apply bg-white p-2 flex  items-center space-x-2 rounded-lg shadow-lg w-[250px] absolute
}
.second_chat_holder {
    @apply bg-white p-2 flex top-[80px] left-[200px] items-center space-x-2 rounded-lg shadow-lg w-[250px] absolute
}
.third_chat_holder {
    @apply bg-white p-2 flex top-[180px]  items-center space-x-2 rounded-lg shadow-lg w-[250px] absolute
}
.side_arrow {
    @apply h-2 w-full bg-grey-180 my-2 rounded-full
}
.chat_contianer {
    @apply flex flex-col space-y-4 relative
}
.arr_holder {
    @apply w-full
}
.text {
    @apply text-[9px] font-semibold text-grey-100
}