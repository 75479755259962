.smooth {
  @apply transition-all duration-300 ease-out;
}

.input_container {
  @apply flex flex-col gap-y-2;
}

.input_label {
  @apply text-sm font-medium relative text-grey-80;
}

.input--required {
  @apply w-1 h-1 absolute ml-[0.8px] top-[5px] rounded-full;
}

.input_field {
  @apply px-[14px] font-medium border-solid text-base py-3 outline-none text-black border-grey-120 border placeholder:text-grey-60 rounded-xl read-only:bg-grey-20 read-only:focus:bg-grey-120 read-only:text-grey-50 read-only:focus:text-grey-50 read-only:border-grey-40 read-only:focus:border-grey-120 focus:border-brand-50 hover:border-brand-50 focus:border-2 focus:bg-grey-0 smooth bg-grey-110;
}

.input--field-sm {
  @apply text-sm;
}

.input--field-reg {
  @apply text-base;
}

.required {
  @apply text-brand-50
}