.body {
    @apply mt-[15vh] md:px-48 px-8
}

.title_bar_text {
    @apply text-[#0D0D12] text-base font-bold 
}
.title_bar {
    @apply flex items-center space-x-4 mt-4 mb-8
}
.back_btn {
    @apply rounded-full border-solid border bg-white border-[#F0F0F0] py-1 cursor-pointer
}
.row {
    @apply flex justify-between items-start mt-2
}
.header_row {
    @apply flex justify-between items-center
}
.cash_type {
    @apply bg-[#FB634B] text-center p-2 leading-none rounded-lg text-xs text-white font-medium
}

.asset_type {
    @apply bg-[#26C6DA] text-center p-2 leading-none rounded-lg text-xs text-white font-medium
}
.request_amount {
    @apply text-grey-160 text-base md:text-3xl font-bold
}
.overdue {
    @apply bg-grey-130 border-none p-2 rounded-lg text-white text-xs font-medium
}
.title {
    @apply text-grey-210 text-xs font-medium
}
.label {
    @apply text-sm font-medium text-grey-160
}
.type_holder {
    @apply flex flex-col space-y-2 items-start 
}
.card {
    @apply bg-white shadow-sm px-4 py-6 rounded-lg mt-4
}
.card_title {
    @apply text-black font-bold text-base
}