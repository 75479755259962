.body {
    @apply px-4 md:px-48 pt-[18vh]
}

.white_card {
    @apply mt-6 min-h-[40vh]
}
.card_nav {
    @apply flex justify-between mb-8
}
.menu {
    @apply flex space-x-4
}
.active_menu_btn {
    @apply text-brand-50 text-xs font-semibold  border-b-brand-30 cursor-pointer bg-grey-110 border-b-2 border-t-0 border-l-0 border-r-0 py-3 border-solid
}

.inactive_menu_btn {
    @apply text-black text-xs font-semibold  border-b-grey-110 cursor-pointer bg-grey-110 border-b-2 border-t-0 border-l-0 border-r-0 py-3 border-solid
}
.trans_card_title {
    @apply text-3xl md:text-4xl tracking-[-0.36px] font-semibold
}
.trans_card_btn {
    @apply border-solid border-[0.5px] border-brand-120 rounded-full px-4 mx-2 py-2 text-xs font-bold bg-white opacity-70  text-brand-120
}
.empty_card {
    @apply flex flex-col items-center justify-center 
}
.empty_title {
    @apply text-base font-bold mt-4
}
.empty_sub_title {
    @apply text-sm font-medium text-grey-130 mt-1
}
.filter_holder {
    @apply flex w-[90%] my-4 space-x-4
}
.filter_container {
@apply flex items-center space-x-2 border-solid border border-grey-120 rounded-lg p-2 bg-white
}
.ref_id_input {
    @apply bg-grey-110 outline-none border-none me-20
}
.filter_text {
    @apply text-[#A4ACB9] text-sm font-medium me-10
}
.request_card_holder {
    @apply flex flex-wrap gap-6
}
.filter_title {
    @apply relative h-10 
}
.filter_holder_popover {
  @apply  absolute z-10 border rounded-lg bg-white p-4 shadow-lg w-full
}
.filter_holder_popover p:hover {
@apply text-white bg-brand-0 cursor-pointer p-1 px-2 rounded-lg
}
.filter_holder_popover p {
    @apply text-black bg-white cursor-pointer p-1 px-2 rounded-lg
    }

.filter_holder_popover button {
    @apply outline-none border-none bg-red-40
}