.body{
    @apply bg-white p-4 rounded-lg  md:min-w-[45%]  md:w-auto
}
.row {
    @apply flex justify-between items-start
}
.header_row {
    @apply flex justify-between items-center
}
.header{
    @apply flex items-center space-x-2
}
.type_holder {
    @apply flex flex-col space-y-2 items-start 
}
.cash_type {
    @apply bg-[#FB634B] text-center p-2 leading-none rounded-lg text-xs text-white font-medium
}

.asset_type {
    @apply bg-[#26C6DA] text-center p-2 leading-none rounded-lg text-xs text-white font-medium
}
.request_amount {
    @apply text-grey-160 text-base font-semibold
}
.overdue {
    @apply bg-grey-130 border-none p-2 rounded-lg text-white text-xs font-medium
}
.title {
    @apply text-grey-210 text-xs font-medium
}
.label {
    @apply text-sm font-medium text-grey-160
}
.btn {
    @apply w-full cursor-pointer text-grey-160 text-sm font-bold mt-4 py-2 bg-white rounded-lg border-grey-120 border-solid border
}