.navlink_holder {
  min-height: 100vh;
  @apply bg-black flex flex-col w-full  h-full relative 
}
.navlink_list {
  @apply flex flex-col mt-10 h-full overflow-y-auto px-4  justify-between gap-y-6;
  min-height: 80vh;
}
.navlink_container {
  @apply flex items-center text-white text-base font-medium transition-all duration-300 ease-in-out rounded-[60px] hover:bg-[#1d2023];
}

.navlink_container .active {
  @apply bg-[#1d2023];
}

.sub_container {
  @apply flex items-center gap-x-[14px];
}

.sub_group {
  @apply flex flex-col gap-y-1;
}

.sub_group h2 {
  @apply text-xs font-medium text-white text-opacity-60 uppercase pl-[14px];
}

.sub_group > div {
  @apply flex flex-col gap-y-[14px];
}

.bottom {
  @apply mt-auto flex flex-col gap-y-6;
}

.user_info {
  @apply flex gap-x-3 items-center ml-2;
}

.user_info > div {
  @apply flex flex-col text-sm;
}

.user_info > div h2 {
  @apply font-bold text-white;
}

.user_info > div h3 {
  @apply font-medium text-[#F2F4F799] text-opacity-60;
}
.navlink_icon {
  @apply relative flex flex-col items-end w-[120px] px-4 pt-8
}
.logo_text {
  @apply text-[10px] font-semibold text-white opacity-60
}
.add_card {
  @apply bg-grey-140 rounded-lg mb-4 relative border-[0.5px] border-grey-60 px-8 py-4}

  .card_title {
    @apply text-white font-bold text-[15px]
  }

  .card_sub_title {
    @apply text-xs font-normal text-white opacity-70
  }
  .sub_card {
    @apply flex justify-center items-center space-x-2 bg-grey-140 rounded-lg px-4
  }
  .card_text_holder {
    @apply flex flex-col py-4
  }