.body {
    @apply px-4 md:px-48 pt-[18vh]
}

.white_card {
    @apply mt-6 min-h-[40vh]
}
.card_nav {
    @apply flex justify-between mb-8
}
.trans_card_title {
    @apply text-3xl md:text-4xl tracking-[-0.36px] font-semibold
}
.trans_card_btn {
    @apply border-solid border-[0.5px] border-brand-120 rounded-full px-4 mx-2 py-2 text-xs font-bold bg-white opacity-70  text-brand-120
}
.empty_card {
    @apply flex flex-col items-center justify-center 
}
.empty_title {
    @apply text-base font-bold mt-4
}
.empty_sub_title {
    @apply text-sm font-medium text-grey-130 mt-1
}
.filter_holder {
    @apply flex w-[90%] my-4 space-x-4
}
.filter_container {
@apply flex items-center space-x-2 border-solid bg-white border border-grey-120 rounded-lg p-2 
}
.ref_id_input {
    @apply bg-grey-110 outline-none border-none me-20
}
.filter_text {
    @apply text-[#A4ACB9] text-sm font-medium me-10
}
.transaction_card_holder {
    @apply flex flex-col space-y-4 bg-white p-8 rounded-lg
}
.transaction_row {
    @apply flex justify-between w-full
}
.transaction_row_head {
    @apply flex flex-col space-y-1
}
.transaction_row_title {
    @apply text-grey-160 text-sm font-normal
}
.transaction_row_label {
    @apply text-grey-210 text-xs font-medium
}
.transaction_row_credit {
    @apply text-sm text-[#048848] font-medium
}

.transaction_row_dedit {
    @apply text-sm text-[#E10000] font-medium
}
.transaction_row_dovider {
    @apply h-[1px] bg-grey-120 w-full
}
.empty_body {
    @apply bg-white flex flex-col justify-center  items-center min-h-[40vh]
}
.content_card_title {
    @apply text-base font-bold mt-4
}
.content_card_text {
    @apply text-xs text-grey-130
}