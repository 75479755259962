.navlink_container {
  @apply flex items-center p-4 text-white text-base font-medium transition-all duration-300 ease-in-out rounded-[60px] bg-brand-50;
}
.navactivelink_container {
  @apply flex items-center p-4 w-[80%] text-white text-base font-medium transition-all duration-300 ease-in-out rounded-[60px] hover:bg-brand-50 ;
}
.navlink_container.active {
  @apply bg-[#1d2023];
}

.sub_container {
  @apply flex items-center gap-x-[14px];
}

