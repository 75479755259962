.body {
    @apply h-full absolute bg-grey-170 flex justify-center items-end md:items-center z-50;
    width:100vw;
    left: 0;
    top:0
}
.left_body {
    @apply h-full absolute bg-grey-170 flex justify-end items-center z-50;
    width:100vw;
    left: 0;
    top:0
}
.container {
    @apply md:w-[35%] bg-white rounded-t-3xl md:rounded-xl p-4 md:p-8 w-full
}
.left_container {
    @apply  md:w-[35%] bg-white rounded-xl p-4 md:p-8 mx-2 md:mx-4 h-[90vh] w-full flex flex-col justify-between

}
.header {
    @apply flex justify-between items-center;
}
.title {
    @apply text-black text-xl md:text-2xl font-semibold
}
.footer {
    @apply flex md:flex-row flex-col-reverse md:space-x-4 mt-4  justify-end
}
.close_btn {
    @apply bg-white rounded-full border-solid p-2 border-[0.5px] cursor-pointer;
}
.ok_btn_holder {
    @apply md:w-[70%]
}
.cancel_btn_holder {
    @apply md:w-[30%] mt-4 md:mt-0
}