.profile_body {
    @apply flex flex-col relative justify-between min-h-full lg:px-8 px-4
}

.title_bar {
    @apply flex items-center space-x-4 mt-4 mb-6
}

.title_bar_text {
    @apply text-[#0D0D12] text-[17px] font-bold
}

.ref_first_div {
    @apply border-[#ECEFF3] border rounded-xl border-solid lg:p-6 p-4 lg:mb-2 mb-4
}

.title_bar_wrap {
    @apply flex items-center
}

.title_bar_button {
    @apply mr-[16px] px-4 py-3 bg-white cursor-pointer;
    border: 1px solid #F0F0F0;
    border-radius: 50%;
}

.wrap {
    @apply flex flex-row justify-between mb-4 pb-3 border-b border-[#EAECF0]
}

.refname_p {
    @apply mb-[12px] font-medium text-sm
}

.refHis_flex {
    @apply flex
}

.refHis_p {
    @apply mr-[12px] text-xs font-medium text-[#475467]
}

.refHis_p2 {
    @apply text-xs font-medium text-[#475467]
}

.refHis_p3 {
    @apply text-[#475467] text-xs font-bold
}

.refHis_IB {
    @apply inline-block
}

.refHis_span1 {
    @apply text-[#33AD71]
}

.refHis_span2 {
    @apply text-[#E1B324]
}