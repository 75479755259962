.body {
    @apply my-4
}
.row {
    @apply flex justify-between
}
.title {
    @apply text-sm font-bold text-grey-80
}
.time {
    @apply text-[11px] font-semibold text-grey-210;
}
.description {
    @apply text-grey-130 text-sm
}