.body {
    @apply w-full 
}
.nav {
    @apply flex justify-between items-center md:px-24 py-4 px-4 bg-yellow-0  md:shadow-none shadow-lg;
    /* @apply left-0 flex items-center justify-between md:px-24 py-4 px-4 bg-yellow-0  w-[100vw] md:shadow-none shadow-lg ; */
}
.hero_holder {
    @apply flex flex-col md:flex-row items-center  md:px-24 px-4  bg-yellow-0 relative pb-12 overflow-x-hidden md:justify-between;
}
.tribar {
    @apply md:hidden outline-none
}

.action_holder {
    @apply hidden md:relative flex-col md:flex-row bg-yellow-0 right-0 p-2 rounded-lg md:space-y-0 space-y-4 shadow-lg md:shadow-none top-[8vh] md:top-0   md:flex md:space-x-4
}

.action_holder_active {
    @apply absolute md:relative flex-col md:flex-row bg-yellow-0 right-0 p-2 rounded-lg md:space-y-0 space-y-4 shadow-lg md:shadow-none top-[8vh] md:top-0  flex md:flex md:space-x-4
}

.action_btn {
    @apply text-black outline-none border-none bg-yellow-0 text-sm font-bold px-2 flex items-center space-x-1
}

.contact_btn {
    @apply cursor-pointer bg-brand-50 py-3 px-6 rounded-xl text-white font-bold border-none
}

.header_btn_container {
    @apply flex relative 
}
.header_product_container {
    @apply flex relative 
}

.header_product_container:hover .header_options_holder {
    @apply h-[110px] p-4
}

.header_options_holder {
    @apply absolute flex flex-col items-start bg-yellow-0 top-[5vh]  shadow-lg rounded-xl space-y-2 min-w-[150px] h-[0px] overflow-hidden
}
.btn_option {
    @apply text-[#36394A] text-sm px-4 py-2 hover:bg-grey-120 rounded-full
}

.hero_footer {
    @apply absolute bottom-0 w-[vw];
}

.hero_first {
    @apply flex flex-col items-start pt-[10vh] w-full md:w-[40%] z-10;
}
.hero_title {
    @apply md:text-8xl text-6xl font-semibold text-black text-start leading-[64px] tracking-[-1.24px];
}

.hero_subtitle {
    @apply md:w-[40vw]  text-[#666D80] text-base my-4;
}

.hero_red {
    @apply text-brand-50;
}

.hero_buttons_holder {
    @apply flex flex-col mt-4 md:flex-row md:space-x-2 space-y-2 md:space-y-0;
}

.hero_btn {
    @apply text-white bg-brand-50 px-8 py-3 rounded-lg font-bold;
}

.hero_lgoin_btn {
    @apply  cursor-pointer text-black px-8 py-3 rounded-lg font-bold !border bg-transparent  border-black hover:text-white hover:bg-black
}
.hero_lgoin{
    @apply  text-black  rounded-lg font-bold !border bg-transparent  border-black hover:text-white 

}
.hero_lgoin_btn:hover .hero_lgoin {
    @apply text-white
}
.video_holder {
    @apply w-full md:w-[40vw] md:h-[55vh]  rounded-xl relative ;
} 

.video{
    @apply w-full h-full rounded-xl mt-8 ;
    object-fit:cover;
}

.footer {
    @apply bg-black md:p-12 md:px-24 p-4 
  }
  .last_footer {
    @apply flex flex-col md:items-center
  }
  .lower_footer_text {
    @apply text-sm text-white opacity-70
  }
  .policy_holder {
    @apply flex my-4 items-center
  }
  .policy_divider {
    @apply h-2 mx-2 bg-white opacity-70 w-[0.5px]
  }

.first {
    @apply flex flex-col md:flex-row w-full justify-between mt-4 space-y-4
  } 
  .link_title {
    @apply text-white opacity-90 text-lg font-semibold
  }
  .link {
    @apply text-white opacity-70 text-base font-medium my-4 cursor-pointer
  }
  .social_icons_holder{
    @apply flex space-x-8
  }

.divider {
    @apply h-[0.2px] bg-white w-full my-4 opacity-20
  }
  .mesenger_logo {
    @apply h-[20px] w-[51px]
  }
  .description_text {
    @apply text-white text-[10px] font-medium
  }
  .descrtiption_holder {
    @apply flex gap-2 items-center
  }

 .last_tab {
    @apply bg-black md:px-24 px-4 pt-12 flex flex-col md:flex-row md:items-center md:justify-between
 }
 .last_title {
    @apply text-white text-5xl md:text-6xl leading-normal font-semibold
 }

 .devices_label {
    @apply text-white opacity-70 text-base font-medium my-4
 }
 .device_btn_holder {
    @apply flex space-x-4
 }
 .device_btn {
    @apply flex  items-center space-x-2 rounded-lg border py-1 px-4
 }

 .home_phone{
    @apply flex justify-start md:h-[60vh] md:ms-8  w-full md:w-[75%] mt-4 md:mt-0
 }
.desk_phone {
    @apply w-full h-full object-contain
}
.third_part {
    @apply flex flex-col md:items-center md:pt-24 py-4 bg-yellow-0 overflow-hidden
}
.third_part_div {
    @apply flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between min-w-full  mb-4 items-center
}
.third_part_div div {
    @apply md:mx-24 mx-4
}
.third_yellow_container {
    @apply min-w-[82%] w-[82%] flex flex-col justify-between md:flex-row md:p-8 p-4 bg-yellow-10 mt-4 rounded-xl items-center md:space-x-12

    /* @apply min-w-[90%] w-[90%] flex flex-col justify-between md:flex-row md:p-8 p-4 bg-yellow-10 mt-4 rounded-xl items-center md:space-x-12 */
}
.third_get_start_btn {
    @apply md:mx-24 mx-4 flex bg-brand-50 space-x-1 items-center py-3 px-4 rounded-xl text-white text-sm font-bold outline-none border-none w-[130px] cursor-pointer
}

.get_started_text{
    @apply md:text-[3rem] text-5xl font-semibold md:leading-[50px] mt-2 leading-[35px]
}
.third_yellow_title {
    @apply text-5xl md:text-6xl font-semibold leading-none
}
.third_yellow_text{
    @apply text-[#141414CC] opacity-80 my-4
}

.services {
    @apply  flex overflow-x-scroll space-x-8 pb-24 md:px-24 px-4 
}
.arr_holder {
    @apply flex gap-3
}
.left_arr {
    @apply rounded-full border-none bg-red-10 cursor-pointer
}
.right_arr {
    @apply rounded-full border-none bg-red-50 cursor-pointer
}
.first_third_yellow_card {
    @apply md:w-[45%] 
}
.ondemand_image {
    @apply md:w-[45%] flex justify-center items-center md:h-[50vh] rounded-lg mt-12 md:mt-0 bg-yellow-0;
    /* @apply md:w-[45%]  flex justify-center items-end md:h-[50vh] rounded-lg mt-12 md:mt-0 bg-yellow-0; */
    background-image: url("../../assets/svgs/ondemandbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
}
.ondemand_bike {
    @apply md:w-[45%] flex justify-center items-center md:h-[50vh] rounded-lg mt-12 md:mt-0 bg-yellow-0;
    background-image: url("../../assets/svgs/ondemandbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.ondemand_gift {
    @apply md:w-[45%] flex justify-center items-center md:h-[50vh] rounded-lg mt-12 md:mt-0 bg-yellow-0;
    background-image: url("../../assets/svgs/ondemandbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.yellow_image {
    @apply w-[70%] rounded-lg mt-12 md:mt-0
}

.bike_image {
    @apply w-[60%] rounded-lg mt-12 md:mt-0
}

@media (max-width:640px) {
    .nav{
        box-shadow: 0px 12px 16px -4px rgba(31, 32, 36, 0.03);
    }
}
.services {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
 }
 .services::-webkit-scrollbar {
    display: none;
}