.normal_primary_button {
    @apply text-white cursor-pointer text-sm flex justify-center items-center font-bold bg-brand-50 px-8 rounded-lg border-none w-full disabled:opacity-25 disabled:cursor-not-allowed;
    text-decoration: none;
}
.small_primary_button {
    @apply text-white text-xs py-1 flex justify-center items-center font-medium px-4 rounded-full border w-full;
    text-decoration: none;
}
.normal_secondary_button {
    @apply text-black text-sm  cursor-pointer font-bold !bg-white border-grey-120 border-solid border px-8 rounded-lg w-full;
    text-decoration: none;
}
.small_secondary_button {
    @apply text-black text-sm  py-4 font-bold !bg-white border-black border px-4 rounded-full w-full;
    text-decoration: none;
}
.normal_tertiary_button {
    @apply text-black font-bold   !bg-grey-30  border-0 border-grey-30 p-2 text-sm rounded-full min-w-max;
    text-decoration: none;
}
.small_tertiary_button {
    @apply text-black  font-medium  !bg-grey-30  border-0 border-grey-30 px-2 py-1  text-xs rounded-full min-w-max;
    text-decoration: none;
}
.inner_button {
    /* @apply   py-2 font-bold bg-transparent px-4 rounded-full min-w-max; */
    text-decoration: none;
}
.warn_text {
    @apply text-center text-grey-200
}
.loading_btn {
    @apply my-3
}