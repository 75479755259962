.body {
    @apply flex w-full justify-between items-center bg-white absolute shadow-md z-20
}
.title {
@apply text-black font-bold text-xl px-12 
}
.options_holder {
    @apply flex px-2 md:px-8 space-x-2 relative
}
.option_profile {
    @apply flex space-x-2 items-center rounded-full;
}

.active_option_profile {
    @apply flex space-x-2 items-center rounded-full bg-brand-0;
}
.option {
    @apply flex p-1 border rounded-full  items-center space-x-2 cursor-pointer
}
.name {
    @apply bg-grey-110 rounded-full p-2 text-sm text-grey-160 font-bold
}
.option_profile:hover .name{
    @apply bg-brand-0

}
.active_name {
    @apply bg-brand-0 rounded-full p-2 text-sm text-grey-160 font-bold

}
.notification {
    @apply rounded-full  bg-grey-110 px-2 border  flex items-center cursor-pointer
}
.notification:hover, .option_profile:hover {
    @apply bg-brand-0
}
.link{
    @apply text-grey-210 md:text-base text-[11px] justify-between md:w-auto w-[25%] font-semibold flex flex-col md:py-4 items-center
}
.activeLink {
    @apply text-brand-50 md:text-grey-190 py-2 md:w-auto w-[25%] flex flex-col justify-between items-center md:py-4 md:text-base text-[11px] font-semibold border-b-0 md:border-b-2 border-solid border-brand-50  border-t-2 md:border-t-0 border-l-0 border-r-0

}
.inactive, .active {
    @apply md:hidden
}
.activeLink .inactive {
    @apply hidden
}
.link .active {
    @apply hidden
}
.header {
    @apply flex md:space-x-10 items-center p-0 md:px-12
}
.menu {
    @apply flex md:w-auto w-[100vw] bg-white items-center md:space-x-4 p-0 fixed md:relative bottom-0
}
.icon {
    @apply md:hidden
}
.mobile_header{
    @apply md:hidden mx-3 my-4
}
.normal_header {
    @apply hidden md:inline 
}

.notification_holder {
    @apply md:w-[500px] max-h-[500px] mx-4 md:mx-0  absolute md:right-10 bg-white z-50 top-[8vh] rounded-xl py-4 px-4;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.notification_btn {
    @apply flex  items-center cursor-pointer justify-center p-2 rounded-lg border border-grey-120 border-solid text-grey-160 text-sm font-bold  bg-white
}
.notifications_row_divider {
    @apply h-[1px] bg-grey-120 w-full
}
.profile_from_down {
    @apply  absolute bg-white  w-[300px]  right-5 top-[7vh] rounded-xl;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

}
.profile_row {
    @apply flex items-center space-x-3 px-4 py-2
}
.profile_row:hover {
    @apply bg-brand-0
}

.divider {
    @apply h-[0.5px] w-full bg-grey-50
}

.dropdown_overlay {
    @apply fixed  left-0 w-[100vw] h-[100vh] z-[90px] top-0
}