.body {
    @apply flex flex-col md:w-[50%] w-full items-start;
}
.logo_holder {
    @apply flex flex-col items-end md:mt-10 md:mx-10 m-5 
}
.header {
    @apply border-b md:border-none border-b-grey-30 border-solid border-l-0 border-r-0 border-t-0 w-full flex justify-start
}
.logo_banner {
    @apply text-[8px] font-medium text-black
}
.container {
    @apply w-[90%] md:w-[80%] mx-auto mt-10 
}
.success_container {
    @apply w-[65%] mx-auto mt-10 
}
.title {
    @apply text-black text-center font-bold text-3xl tracking-[-0.36px]
}
.warn_text {
    @apply text-grey-130 text-base text-center font-medium
}

.forgot_password_text {
    @apply text-brand-50 text-base font-semibold cursor-pointer
}
.successIConHolder {
    @apply flex justify-center
}
.red_warn {
    @apply text-brand-50 font-bold cursor-pointer
}
.options_btn {
    @apply flex items-center space-x-2 p-4 rounded-lg border-solid border-grey-110 w-full bg-grey-110 cursor-pointer;
    border-width: 1.5px;
}
.option_btn_text {
    @apply text-black text-sm font-medium
}
.selected_options_btn {
    @apply flex items-center space-x-2 p-4 rounded-lg border-solid border-brand-50 w-full bg-brand-130 cursor-pointer;
    border-width: 1.5px;
}
.counter_holder {
    @apply bg-[#F6F8FA] rounded-full p-3 flex justify-center items-center
}
.counter_text {
    @apply text-brand-50 text-center text-sm font-bold
}
.back_btn {
    @apply bg-white border-none cursor-pointer
}
.counter_container {
    @apply flex justify-center
}