.menu_holder {
    @apply flex items-center my-8 space-x-4 flex-wrap
}

.menu_text {
    @apply text-xs flex items-center space-x-1 text-grey-160 hover:text-brand-50 hover:underline hover:underline-offset-4 font-semibold cursor-pointer mb-1
}

.menu_text_active {
    @apply flex items-center space-x-1 text-xs text-brand-50 font-semibold underline underline-offset-4 md:pb-1
}

.body {
    @apply pt-[18vh] px-0 md:px-48
}

.child_container {
    @apply bg-white lg:min-h-[85vh] min-h-[70vh] mt-8 rounded-3xl shadow-sm mb-8 border-solid border-[0.5px] border-grey-20
}

.profile_body {
    @apply flex flex-col relative justify-between min-h-full lg:px-8 px-4
}

.account_title_header {
    @apply text-[#0D0D12] font-semibold mb-8 text-4xl
}

.profile_picture {
    @apply h-[20vh] w-[20vh] rounded-full z-10
}

.account_title {
    @apply text-[#0D0D12] text-base font-semibold my-8
}

.title_bar_text {
    @apply text-[#0D0D12] text-base font-bold
}

.picture_holder {
    @apply h-[20vh] w-[10vw] mb-8 relative
}

.profilePictureInput {
    @apply hidden
}

.camera_holder {

    @apply absolute bg-white p-2 w-[auto] rounded-full;
    bottom: 0;
    right: 0;
}

.text_holder {
    @apply flex lg:flex-row md:flex-row flex-col justify-between mb-2 w-full
}

.text_container {
    @apply lg:w-[49%] md:w-[49%] w-full
}

.address_container {
    @apply w-full flex flex-col
}

.address_container button {
    @apply justify-center items-center flex space-x-2 cursor-pointer px-[14px] mt-2 font-medium border-solid text-base py-3 outline-none text-black border-grey-120 border placeholder:text-grey-60 rounded-xl read-only:bg-grey-20 bg-grey-110
}

.virtual_nin_warn {
    @apply text-xs font-medium text-grey-210
}

.nin_red {
    @apply text-brand-50
}

.birth_holder {
    @apply w-[60%]
}

.footer {
    @apply flex lg:justify-end md:justify-end justify-center mb-4 lg:w-[15vw] md:w-[15w];
    align-self: flex-end;
}

.row {
    @apply flex items-center justify-between my-4 cursor-pointer
}

.divider {
    @apply h-[1px] bg-grey-20
}

.row_option {
    @apply text-sm font-medium text-[#0D0D12]
}

.bvn_format {
    @apply text-xs text-[#818898] font-medium
}

.title_bar {
    @apply flex items-center space-x-4 mt-4 mb-8
}

.back_btn {
    @apply rounded-full border-solid border bg-white border-[#F0F0F0] py-2 px-2 cursor-pointer flex items-center justify-center
}

.info_holder {
    @apply flex items-center p-2 border-[0.5px] border-solid rounded-md text-xs text-[#325280] font-semibold mb-6 space-x-2 bg-[#E4F0FE]
}

.verified_btn {
    @apply text-[#184E44] font-semibold text-xs p-1 px-2 bg-[#DDF3EF] rounded-full
}

.pending_btn {
    @apply text-[#e3d149] font-semibold text-xs p-1 px-2 bg-[#f3efdd7c] rounded-full
}

.loading_body {
    @apply flex w-full h-full justify-center items-center
}

.add_button {
    @apply fixed bottom-[10vh] right-[20vw] cursor-pointer bg-brand-50 text-white p-3 px-4 text-lg rounded-full border-none
}

.empty_container {
    @apply flex flex-col items-center mb-20
}

.empty_title {
    @apply text-base font-bold leading-none
}

.empty_sub_title {
    @apply text-xs text-grey-60 font-medium w-[15vw] text-center
}

.tier_holder {
    @apply flex items-center gap-2 bg-yellow-400 rounded-full px-4
}

.tier_title {
    @apply text-sm font-bold
}

.account_tier_holder {
    @apply flex items-center gap-4
}

.ref_first_div {
    @apply border-[#ECEFF3] border rounded-xl border-solid lg:p-6 p-4 mb-6
}

.ref_first1_div {
    @apply flex lg:flex-row flex-col justify-between pb-6
}

.ref_first1_div_flex {
    @apply flex basis-[47%] items-center
}

.ref_second_div {
    @apply border-[#ECEFF3] border rounded-xl border-solid p-6 mb-9
}

.ref_second_first {
    @apply mb-[30px]
}

.ref_second_first_p {
    @apply lg:text-[20px] text-base font-bold leading-[22px] mb-[6px]
}

.ref_second_first_p2 {
    @apply text-[14px] font-medium text-[#000000B2]
}

.ref_second_first_span {
    @apply text-[#DE183D]
}

.ref_second_second {
    @apply hidden px-[40px] lg:flex justify-between
}

.ref_second_second_div {
    @apply flex flex-col items-center basis-[30%]
}

.ref_second_second_div_r {
    @apply flex flex-col items-center basis-[30%] relative
}

.ref_second_second_div2 {
    @apply pb-[16px]
}

.ref_second_second_p {
    @apply text-center text-[15px] font-medium
}

.ref_second_second_ab {
    @apply absolute top-1 -left-[32%]
}

.ref_second_second_ab2 {
    @apply absolute top-[35%] -left-[32%]
}

.ref_second_second_alt {
    @apply lg:hidden block
}

.ref_second_second_alt_div {
    @apply flex items-center pb-4
}

.ref_second_second_alt_diva {
    @apply flex items-center
}

.ref_second_second_alt_1 {
    @apply pr-[16px]
}

.ref_second_second_alt_p {
    @apply text-[14px] font-medium
}

.top_left {
    @apply lg:pr-[16px]
}

.svg_view {
    @apply lg:block hidden
}

.top_left_text1 {
    @apply text-[12px] mt-0 mb-2
}

.top_left_text2 {
    @apply lg:text-[22px] text-[20px] font-bold m-0
}

.top_center {
    @apply lg:w-[1px] w-[100%] border-[#ECEFF3] border border-solid lg:my-0 my-4 bg-[#ECEFF3]
}

.ref_first2_div {
    @apply flex justify-between lg:pt-[19px] pt-2 bg-[#141414] rounded-xl mb-6
}

.ref_first2_div1 {
    @apply lg:pl-[32px] pl-[16px] lg:pt-3 pt-2 text-white
}

.ref_first2_div1_text {
    @apply font-semibold text-[14px] pb-6 text-[#FFFFFFB2]
}

.ref_first2_div1_text1 {
    @apply font-bold lg:text-[22px] text-[12px] lg:pr-4 pr-2
}

.ref_first2_div1_flex {
    @apply flex items-center
}

.ref_first2_div1_button {
    @apply p-0 bg-inherit border-0 cursor-pointer
}

.ref_first2_div1_svg {
    @apply lg:w-auto w-[24px]
}

.ref_first2_div2 {
    @apply lg:pr-[64px] pr-0
}

.ref_first3_wrap {
    @apply flex
}

.ref_first3_wrap_inner {
    @apply mr-3
}

.ref_first3_button1 {
    @apply flex lg:flex-row flex-col items-center px-5 py-3 bg-[#FDF2F4] rounded-lg text-[15px] font-semibold text-[#DE183D] border-0 cursor-pointer
}

.ref_first3_button1_span {
    @apply pr-2
}

.ref_first3_button1_svg {
    @apply lg:h-auto h-[18px]
}




.account_wrapper {
    @apply lg:w-[60%] md:w-[80%] w-full mx-auto
}

.account_form_wrapper {
    @apply flex justify-center items-center pt-[24px]
}

.pic_wrapper {
    @apply h-[20vh] md:w-[10vw] mb-[8px] relative
}

.account_img {
    @apply h-[20vh] lg:w-[20vh] md:w-full w-full rounded-full z-10
}

.account_name {
    @apply text-[18px] font-semibold text-center
}

.navlink_wrapper {
    @apply my-6 px-3
}

.navlink1 {
    @apply flex justify-between items-center py-4;
    border-top: 1px solid #F2F4F7;
    border-bottom: 1px solid #F2F4F7;
}

.navlink {
    @apply flex justify-between items-center py-4;
    border-bottom: 1px solid #F2F4F7;
}

.navlink_left {
    @apply text-black flex items-center
}

.nav_left_svg {
    @apply pr-[16px]
}

.nav_left_text {
    @apply text-[16px] font-medium
}

.navlink_right {
    @apply flex items-center
}

.nav_right_svg {
    @apply pr-[8px]
}

.title_bar_wrap {
    @apply flex items-center
}

.title_bar_button {
    @apply mr-[16px] px-4 py-3 bg-white cursor-pointer;
    border: 1px solid #F0F0F0;
    border-radius: 50%;
}

.pass_text {
    @apply text-[#818898] font-medium text-[12px]
}

.pass_span {
    @apply text-[#36394A]
}
.general_repayment_account_holder {
    @apply flex justify-between my-4 px-4 space-x-2
}
.general_account_container {
    @apply flex border border-solid border-gray-300 px-4 w-[42%] rounded-lg items-center gap-4 justify-between
}
.repay_account_title {
    @apply text-[#666D80] text-[10px] font-medium
}
.account_details_container {
    @apply flex flex-col md:flex-row gap-2 my-4 mt-4
}
.repay_account_numner {
    @apply text-[8px] md:text-base text-[#0D0D12] font-medium leading-[1px]
}
.repayment_bank {
    @apply text-[10px] md:text-xs text-[#666D80]
}